<template>
  <q-page class="locationPage">
    <div class="page-title" v-if="!isMobile">
      <div class="page-title--left">
        <h1>How would you like your order?</h1>
        <q-breadcrumbs v-if="currentLocationId">
          <q-breadcrumbs-el label="Home" :to="{ name: 'Home' }" />
          <q-breadcrumbs-el label="Find a Store" />
        </q-breadcrumbs>
      </div>
    </div>
    <div class="page-content">
      <SearchLocation />
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'PageSearchLocation',
  components: {
    // lazy load component
    SearchLocation: () => import('components/location/SearchLocation'),
  },
  meta() {
    return {
      title: this.pageTitle,
      ...this.commonMeta,
      meta: {
        title: {
          name: 'title',
          content: this.metaTitle,
        },
        description: {
          name: 'description',
          content: this.metaTitle,
        },
        ogTitle: {
          property: 'og:title',
          content: this.pageTitle,
        },
        ogDescription: {
          property: 'og:description',
          content: this.pageTitle,
        },
        ogUrl: {
          property: 'og:url',
          content: this.currentRoutePath,
        },
      },
      link: {
        canonical: {
          rel: 'canonical',
          href: this.currentRoutePath,
        },
      },
    }
  },
  data() {
    return {
      pageTitle: 'Search Location',
    }
  },
  computed: {
    metaTitle() {
      if (this.businessName) return `${this.businessName} - ${this.pageTitle}`
      else return this.pageTitle
    },
  },
}
</script>

<style lang="scss">
// new css end
.locationPage {
  .page-title {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
      padding: 20px 40px;
    }
    @media (max-width: 1023px) {
      .page-title--left,
      .page-title--right {
        padding: 10px 20px;
      }
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 15px;
      line-height: normal;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      // color: $primary;
      color: $textdark;
      color: var(--q-color-primary) !important;
      strong {
        font-weight: 600;
      }
      @media (min-width: 1440px) {
        font-size: 18px;
      }
      @media (min-width: 1681px) {
        font-size: 20px;
      }
    }
    .q-breadcrumbs {
      font-size: 14px;
      @media (min-width: 1681px) {
        font-size: 16px;
      }
    }
    @media (max-width: 599px) {
      .q-btn {
        width: 100%;
      }
    }
  }

  /* Content */
  .page-content {
    padding: 20px 0;
    @media (min-width: 1024px) {
      padding: 40px 0;
    }
  }

  .deliveryCheckmark {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
    @media (min-width: 1681px) {
      padding: 20px 0;
    }
    @media (max-width: 599px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      padding: 0 5px;
      width: 25%;
      display: flex;
      align-items: center;
      color: #888888;
      font-size: 12px;
      @media (max-width: 599px) {
        width: 50%;
        .q-checkbox {
          flex: 10000 0 0;
        }
      }
      i {
        font-size: 1rem;
        flex: 0 0 auto;
        padding-left: 10px;
        fill: $primary;
        @media (min-width: 1681px) {
          font-size: 1.6rem;
        }
      }
      @media (max-width: 599px) {
        padding: 10px;
        width: 50%;
        position: relative;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        .q-checkbox {
          // position: absolute;
          position: static;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          // opacity: 0 !important;
          margin-top: 8px;
          z-index: 1;
          .q-checkbox__inner {
            display: block;
            position: absolute;
            top: 20px;
            left: 0;
            right: 0;
            margin: 0 auto;
            opacity: 0;
          }
          &[aria-checked='true'] + i {
            background: #f2fbff;
            color: $primary !important;
            border: 1px solid $primary;
            border: 1px solid var(--q-color-primary) !important;
          }
        }
        i {
          margin: 0;
          width: 24px;
          height: 24px;
          padding: 13px;
          background: rgba(0, 0, 0, 0.05);
          color: #8e8e8e !important;
          border: 1px solid #8e8e8e;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
